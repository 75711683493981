import gsap from "gsap";
import imagesLoaded from 'imagesloaded';
import Scrollbar, {ScrollbarPlugin} from "smooth-scrollbar";

class DisableScrollPlugin extends ScrollbarPlugin{
    static pluginName = "disableScroll";
    static defaultOptions = {
        direction:"",
    };
    transformDelta(delta){
        if(this.options.direction){
            delta[this.options.direction] = 0;
        }
        return {...delta};
    }
}

Scrollbar.use(DisableScrollPlugin);


class AnchorPlugin extends ScrollbarPlugin {
    static pluginName = 'anchor';
  
    onHashChange = () => {
      this.jumpToHash(window.location.hash);
    };
  
    onClick = (event) => {
      const { target } = event;
  
      if (target.tagName !== 'A') {
        return;
      }
  
      const hash = target.getAttribute('href');
  
      if (!hash || hash.charAt(0) !== '#') {
        return;
      }
  
      this.jumpToHash(hash);
    };
  
    jumpToHash = (hash) => {
      const { scrollbar } = this;
  
      if (!hash) {
        return;
      }    
  
      // reset scrollTop
      scrollbar.containerEl.scrollTop = 0;
  
      scrollbar.scrollIntoView(document.querySelector(hash));
    };
  
    onInit() {
      this.jumpToHash(window.location.hash);
  
      window.addEventListener('hashchange', this.onHashChange);
  
      this.scrollbar.contentEl.addEventListener('click', this.onClick);
    }
  
    onDestroy() {
      window.removeEventListener('hashchange', this.onHashChange);
  
      this.scrollbar.contentEl.removeEventListener('click', this.onClick);
    }
  }
  
// usage
Scrollbar.use(AnchorPlugin);

class ModalPlugin extends ScrollbarPlugin {
    static pluginName = 'modal';
  
    static defaultOptions = {
      open: false,
    };
  
    transformDelta(delta) {
      return this.options.open ? { x: 0, y: 0 } : delta;
    }
  }
  
Scrollbar.use(ModalPlugin);

let options = {
    damping: .1,
    alwaysShowTracks: true,
    plugins:{
        disableScroll:{
            direction:"x",
        },
    },
}
// inizializzazione della scrollbar
let pageSmoothScroll = Scrollbar.init(document.body, options);
pageSmoothScroll.track.xAxis.element.remove();
// inizializza la scrollbar come 'bloccata'
pageSmoothScroll.updatePluginOptions('modal', { open: true });


/* Animations for landing components ingress in the page*/
gsap.to("header",{
  duration:1,
  delay:.5,
  top:"1rem"
});
/*
gsap.to(".socials",{
    duration:1,
    delay:1,
    bottom:"10rem"
});*/
gsap.to(".scrollDown",{
    duration:1,
    delay:1,
    bottom:"3rem"
});

// attendi 2s e poi sblocca la scrollbar
setTimeout(()=>{
    pageSmoothScroll.updatePluginOptions('modal', { open: false });
},2000);

// FAQ checkboxs open/close
const questions= [...document.querySelectorAll('.question')];
questions.map((question)=>{
    let q_text = question.querySelector("h3");
    q_text,question.addEventListener('click',()=>{
        questions.filter((q)=>q!==question).map((q)=>q.classList.remove("open"))
        question.classList.toggle("open");
    });
});
